@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

$main-colour: #2e7d32;

@mixin flex-column() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.05em;
}

h1 {
  font-weight: 300;
}

.h4-style {
  margin-bottom: 1em;
  padding: 1em;
  text-align: center;
}

header,
footer {
  width: 100%;
  text-align: center;
  min-height: 100px;
  background-color: #202020;
  color: #fff;

  @include flex-column();
}

footer {
  h3 {
    margin-bottom: .35em;
  }

  p {
    margin: 0 auto 1.5em auto;
    font-weight: 300;

    a {
      display: inline-block;
      padding: .15em .35em;
      color: #ffffff;

      &:hover {
        background-color: #fff;
        color: #202020;
      }
    }
  }
}

.bg-content {
  background-color: #fff;
  min-height: 80vh;

  .search-bar {
    @include flex-column();

    flex-direction: row;
    background-color: #f8f8f8;
    padding: 20px;
  }
}

.bg-content-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 50px 50px;
  color: #fff;
}

/* BackgroundMedia */
.body-bg {
  background-repeat: no-repeat;
  background-size: cover;

  .bg-img-overlay {
    width: 100%;
    min-height: 80vh;
    background: rgba(0, 0, 0, 0.5);
    
    @include flex-column();
  }
}

/* TextInputForm */
#suburb-form {
  @include flex-column();

  flex-direction: row;

  input {
    background-color: #fff;
  }

  button {
    height: 56px;
  }
}

/* Masonry */
.collections-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  margin-top: 3em;
}
.collections-masonry-grid-column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.collections-masonry-grid-column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.MuiCardHeader-content {
  span.MuiTypography-root {
    font-size: 1.2em;
  }

  span.MuiCardHeader-subheader {
    font-size: .87em;
  }
}

/* Project Card */
.project-card {
  border-top: 35px solid $main-colour;
  border-bottom: 35px solid $main-colour;
}

/* Carousel */
.listings-carousel {
  max-width: 100%;

  @include flex-column();

  .slick-slider {
    max-width: 400px;
    display: inline-block;
  }
}

.property-thumb {
  h5 {
    font-size: 1.1em;
  }
}

/* Property Card */
.property-detail-icons {
  @include flex-column();

  flex-direction: row;
  justify-content: flex-start;

  svg {
    margin-right: 5px;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}
